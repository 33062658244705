<template>
    <footer>
        <div class="container">
            <div class="footer-top">
                <div class="footer-top-logo">
                    <a href="/" class="footer-logo" v-for="logo in $store.state.site_info?.logo">
                        <img
                            :src="logo?.logo_pc"
                            alt=""
                        />
                    </a>
                </div>
                <div class="footer-top-content">
                    <div class="footer-top-content-col mob">
                        <a href="/" class="footer-logo">
                            <template v-for="(logo, index) in $store.state.site_info?.logo">
                                <img
                                    :src="logo?.logo_mobile"
                                    alt=""
                                />
                                <template v-if="index + 1 != $store.state.site_info?.logo.length">
                                    |
                                </template>
                            </template>
                        </a>
                    </div>
                    <div class="footer-top-content-col">
                        <a href="/">Главная</a>
                        <a v-if="$route.path == '/'" @click="moveTo('#about')">
                            Об акции
                        </a>
                        <a v-else href="/?section=about">Об акции</a>
                        <a href="/catalog">Каталог</a>
                        <a @click="moveTo('#map')">Места приёмов</a>
                    </div>
                    <div class="footer-top-content-col">
                        <!-- <a
                            v-if="$route.path == '/'"
                            @click="moveTo('#curators')"
                        >
                            Партнеры
                        </a> -->
                        <!-- <a v-else href="/?section=curators">Партнеры</a> -->
                        <a href="/contacts">Контакты</a>
                        <a v-if="$store.state.token != null" href="/lk">
                            Личный кабинет
                        </a>
                        <a v-else @click="openAvtoriz">Личный кабинет</a>
                    </div>
                    <div class="footer-top-content-col right">
                        <a :href="'tel:' + $store.state.site_info?.phone">{{
                            $store.state.site_info?.phone
                        }}</a>
                        <a :href="'mailto:' + $store.state.site_info?.email">{{
                            $store.state.site_info?.email
                        }}</a>
                        <div class="socials">
                            <span> Мы в соц. сетях: </span>
                            <a
                                :href="item.link"
                                target="_blank"
                                v-for="item in $store.state.site_info?.media"
                            >
                                <template v-if="isPC">
                                    <img :src="item.img" alt="" />
                                </template>
                                <template v-else>
                                    <img :src="item.img_mob" alt="" />
                                </template>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="footer-info">
                    <span>© 2024</span>
                    <a href="/privacy-policy">Политика конфиденциальности</a>
                    <a
                        :href="$store.state.site_info?.regulation"
                        target="_blank"
                        >Положение</a
                    >
                    <a :href="$store.state.site_info?.questions" target="_blank"
                        >Есть вопросы или предложения?</a
                    >
                </div>
                <div class="footer-dev">
                    <span>Разработка сайта</span>
                    <a href="https://your-startup.space/" target="_blank">
                        <template v-if="isPC">
                            <img
                                :src="require('@/assets/img/ys_logo.svg')"
                                alt=""
                            />
                        </template>
                        <template v-else>
                            <img
                                :src="require('@/assets/img/ys_logo-white.svg')"
                                alt=""
                            />
                        </template>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    props: {
        moveTo: null,
    },
    data() {
        return {
            regulation: JSON.parse(sessionStorage.getItem("site_info"))
                ?.regulation,
            isPC: false,
        };
    },
    methods: {
        checkWindowSize() {
            if (window.innerWidth > 1200) {
                this.isPC = true;
            } else {
                this.isPC = false;
            }
        },
    },
    mounted() {
        this.checkWindowSize();
        window.addEventListener("resize", this.checkWindowSize);
    },
};
</script>

<style>
footer {
    background-color: var(--light-gray);
    color: var(--black);
    padding: 40px;
}

.footer-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.footer-top-content {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
}

.footer-top-content-col {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: max-content;
}

.footer-top-content-col.mob {
    display: none;
}

.footer-top a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    /* color: var(--black); */
    color: #000000;
    cursor: pointer;
}

.footer-logo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-logo img {
    height: 60px;
}

.footer-bottom {
    margin-top: 40px;
    padding: 40px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-info {
    display: flex;
    align-items: center;
    column-gap: 13px;
}

.footer-info ::after {
    content: "|";
    margin-left: 13px;
}

.footer-info a:last-of-type::after {
    display: none;
}

.footer-info,
.footer-info * {
    color: var(--black);
    font-size: 20px;
    text-decoration: none;
    font-weight: 600;
}

.footer-dev span {
    font-size: 14px;
    color: var(--black);
    margin-right: 10px;
    font-weight: 600;
}

.footer-dev img {
    height: 40px;
}

.socials {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.socials a {
    height: 32px;
}

.socials span {
    font-size: 20px;
    color: var(--black);
    font-weight: 600;
}

.yamal-logo {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.yamal-logo span {
    display: block;
    white-space: normal;
}

.footer-top-logo {
    display: flex;
    gap: 15px;
}

.footer-top-logo a {
    display: block;
    width: max-content;
    height: 60px;
}

@media screen and (max-width: 1600px) {
    .footer-logo img {
        height: 45px;
    }
}

@media screen and (max-width: 1200px) {
    footer {
        padding: 20px 0;
        background-color: #264e6b;
    }

    .footer-top-logo {
        display: none;
    }

    .footer-top a {
        font-size: 18px;
        font-weight: 400;
        line-height: 17.91px;
        color: #ffffff;
        font-weight: 400;
    }

    .footer-top-content {
        grid-column-start: 1;
        grid-template-columns: 1fr;
        gap: 0px;
    }

    .footer-top-content-col.mob {
        margin-top: 0px;
        display: block;
    }

    .footer-top,
    .footer-bottom {
        align-items: flex-start;
        gap: 25px;
    }

    .footer-logo img {
        height: 25px;
    }

    .footer-info {
        flex-direction: column;
    }

    .footer-top-content-col {
        margin-top: 40px;
        width: 100%;
        gap: 25px;
    }

    .footer-top-content-col:nth-child(3) {
        margin-top: 25px;
    }

    .footer-top-content-col.right {
        margin-top: 50px;
        align-items: flex-start;
    }

    .footer-info ::after {
        display: none;
    }

    .yamal-logo span,
    .yamal-logo p {
        font-size: 14px;
    }

    .footer-top-content-col:first-of-type {
        width: 100%;
    }

    .socials span {
        color: #ffffff;
        font-weight: 400;
    }

    .footer-bottom {
        border-top: 1px solid #ffffff;
        flex-direction: column;
        padding: 22px 0 30px;
        gap: 25px;
    }

    .footer-info {
        gap: 25px;
        align-items: flex-start;
    }

    .footer-info,
    .footer-info * {
        font-size: 18px;
        font-weight: 600;
        line-height: 22.5px;
        color: #ffffff;
    }

    .footer-dev span {
        color: #ffffff;
    }
}
</style>
