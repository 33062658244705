<template>
    <Page ref="page">
        <mainCatalog ref="catalog" @update="resetScroll" :moveTo="moveTo" />
        <SVGMap ref="map" />
        <RForm ref="form" />
    </Page>
</template>

<script>
import Page from "@/components/base/Page.vue";
import mainCatalog from "@/components/catalog/mainCatalog.vue";
import catalogList from "@/components/catalog/catalogList.vue";
import SVGMap from "@/components/Map.vue";
import RForm from "@/components/Form.vue";

import { mapMutations } from "vuex";

export default {
    name: "Catalog",
    components: {
        Page,
        mainCatalog,
        catalogList,
        SVGMap,
        RForm,
    },
    methods: {
        ...mapMutations({
            setLoading: "page/setLoading",
        }),
        resetScroll() {
            if (this.$refs.page) {
                this.$refs.page.resetScroll();
            }
        },
        moveTo(selector) {
            if (this.$refs.page) {
                this.$refs.page.moveTo(selector);
            }
        },
    },
    mounted() {
        const interval = setInterval(() => {
            if (this.is_ready) {
                this.$refs.page.init();
                this.setLoading(false);

                clearInterval(interval);
            }
        }, 50);
    },
    computed: {
        is_ready() {
            return this.$refs.catalog && this.$refs.map && this.$refs.form;
        },
    },
};
</script>
