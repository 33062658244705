<template>
    <div class="lk-history">
        <h2>
            <span> История подарков </span>
        </h2>
        <div class="lk-history-years">
            <div
                class="lk-history-year"
                v-for="year in yearsList"
                :class="year == current_year ? 'active' : ''"
                @click="setActiveYear(year)"
            >
                {{ year }}
            </div>
        </div>
        <div
            class="history"
            :class="is_loding ? 'loading' : ''"
            v-if="page_data?.length > 0"
        >
            <div class="product-list">
                <product
                    v-for="gift in page_data"
                    :name="gift.name"
                    :age="gift.age"
                    :age_month="gift.age_month"
                    :gender="gift.gender"
                    :is_ordered="gift.is_ordered"
                    :text="gift.text"
                    :link="gift.link"
                    :ordered_text="gift.ordered_text"
                    :ordered_img="gift.ordered_img"
                    :in_catalog="in_catalog"
                    :gift_id="gift.gift_id"
                    :district="gift.city_title"
                    :district_img="gift.city_img"
                />
            </div>
            <catalogPagination
                @change="changePage"
                :count_pages="count_pages"
                :current_page="current_page"
            />
            <div class="catalog-loading"></div>
        </div>
        <div class="no-products" v-else>
            В {{ current_year }} вы не бронировали подарки.
        </div>
    </div>
</template>

<script>
import product from "@/components/catalog/product.vue";
import catalogPagination from "@/components/catalog/catalogPagination.vue";
import historyApi from "@/assets/js/api/history.js";

export default {
    components: {
        product,
        catalogPagination,
    },
    data() {
        return {
            current_year: new Date().getFullYear(),
            page_data: [],
            count_pages: 1,
            posts_per_page: 6,
            current_page: 1,
            is_loding: true,
        };
    },
    methods: {
        setActiveYear(year) {
            this.current_year = year;
            this.changePage(1);
        },
        async changePage(page) {
            this.is_loding = true;

            let data = {
                posts_per_page: this.posts_per_page,
                page: page,
                year: this.current_year,
                token: JSON.parse(localStorage.getItem("token")),
            };

            const result = await historyApi.get(data);

            setTimeout(() => {
                if (result) {
                    this.count_pages = result.count_pages;
                    this.current_page = result.page;
                    this.page_data = result.gifts;
                }
            }, 1000);
        },
    },
    mounted() {
        this.changePage(this.current_page);
    },
    computed: {
        yearsList() {
            let list = [];
            const last_year = new Date().getFullYear();

            for (let index = 2022; index <= last_year; index++) {
                list.push(index);
            }

            return list;
        },
    },
    watch: {
        current_year(new_value) {
            console.log(new_value);
            // TODO : api
            // if (new_value == 2023) {
            //     this.products = [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ];
            // } else {
            //     this.products = [];
            // }
        },
        page_data() {
            this.is_loding = false;
            this.$emit("update");
        },
    },
};
</script>

<style>
.lk-history {
    margin-top: 190px;
}

.lk-history-years {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.lk-history-year {
    font-size: 24px;
    padding: 16px 68px;
    border: 1px solid #145c8e;
    color: #145c8e;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.5s;
}

.lk-history-year.active,
.lk-history-year:hover {
    background-color: #145c8e;
    color: #fff;
}

.no-products {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #145c8e;
    font-size: 36px;
    padding: 100px 0;
    text-align: center;
}

.catalog-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    z-index: 50;
    transition: 0.3s;
}

.history.loading .catalog-loading {
    opacity: 0.8;
    pointer-events: all;
}

@media screen and (max-width: 1200px) {
    .lk-history {
        margin-top: 50px;
    }

    .lk-history-years {
        margin-top: 50px;
    }
}
</style>
