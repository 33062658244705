<template>
    <section id="how-to">
        <div class="container align-center">
            <h2>{{ data?.title }}</h2>
            <div class="how-to-cards">
                <div
                    class="how-to-card"
                    v-for="(item, index) in data?.items"
                    :key="index"
                >
                    <div class="how-to-card-text">
                        <h3>{{ item.title }}</h3>
                        <span v-html="item.text"></span>
                    </div>
                    <div v-if="index == 0" class="how-to-card-img-container">
                        <img
                            class="how-to-card-img-left"
                            src="@/assets/img/how-to/gift-1.png"
                            alt=""
                        />
                        <img
                            class="how-to-card-img-right"
                            src="@/assets/img/how-to/gift-2.png"
                            alt=""
                        />
                    </div>
                    <div
                        v-else-if="index == 1"
                        class="how-to-card-img-container"
                    >
                        <img
                            class="how-to-card-img"
                            src="@/assets/img/how-to/ordered.png"
                            alt=""
                        />
                    </div>
                    <div
                        v-else-if="index == 2"
                        class="how-to-card-img-container"
                    >
                        <img
                            class="how-to-card-img"
                            src="@/assets/img/how-to/how-to-3.png"
                            alt=""
                        />
                    </div>
                    <div
                        v-else-if="index == 3"
                        class="how-to-card-img-container"
                    >
                        <img
                            class="how-to-card-img-left"
                            src="@/assets/img/how-to/how-to-4-1.png"
                            alt=""
                        />
                        <img
                            class="how-to-card-img-right"
                            src="@/assets/img/how-to/how-to-4-2.png"
                            alt=""
                        />
                    </div>
                    <a v-if="index == 3" class="btn desktop" href="/catalog"
                        >Исполнить мечту</a
                    >
                </div>
            </div>
            <a class="btn mobile" href="/catalog">Исполнить мечту</a>
        </div>
    </section>
</template>
<script>
export default {
    name: "HowTo",
    props: {
        data: null,
    },
};
</script>
<style>
#how-to h2 {
    color: var(--black);
    width: 100%;
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin: 0 0 40px;
}

.how-to-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 480px);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.how-to-card {
    position: relative;
    border-radius: 30px;
    padding: 40px;
    background: var(--blue);
    width: 100%;
    color: #fff;
    overflow: hidden;
}

.how-to-card:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
}
.how-to-card:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
}
.how-to-card:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
}
.how-to-card:nth-child(4) {
    grid-area: 3 / 1 / 4 / 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.how-to-card:nth-child(4) a {
    position: relative;
    z-index: 15;
}

.how-to-card h3 {
    margin: 0 0 20px;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.how-to-card-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 380px;
}

.how-to-card:nth-child(1) .how-to-card-text {
    max-width: 480px;
}

.how-to-card:nth-child(2) .how-to-card-text {
    max-width: 360px;
}

.how-to-card:nth-child(3) .how-to-card-text {
    max-width: 290px;
}

.how-to-card:nth-child(3) .how-to-card-text span {
    max-width: 266px;
    position: relative;
    z-index: 10;
}

.how-to-card:nth-child(4) .how-to-card-text {
    max-width: 450px;
}

.how-to-card-img {
    max-width: 100%;
}

.how-to-card:nth-child(1) img {
    width: 628px;
    height: 338px;
    display: block;
    position: absolute;
    transition: 0.8s;
}

.how-to-card:nth-child(1) .how-to-card-img-left {
    top: 273px;
    left: -100px;
    transform: rotate(10deg) translateX(-100%);
    transition-delay: 0.3s;
}

.how-to-card:nth-child(1):hover .how-to-card-img-left {
    transform: rotate(10deg) translateX(0);
    transition-delay: 0s;
}

.how-to-card:nth-child(1) .how-to-card-img-right {
    top: 520px;
    right: -150px;
    transform: rotate(-15deg) translateX(100%);
    transition-delay: 0s;
    z-index: 10;
}

.how-to-card:nth-child(1):hover .how-to-card-img-right {
    transform: rotate(-15deg) translateX(0);
    transition-delay: 0.3s;
}

.how-to-card:nth-child(2) img {
    width: 450px;
    height: 264px;
    display: block;
    transition: 0.8s;
    margin: 40px auto 0;
    transform: translateY(120%);
}

.how-to-card:nth-child(2):hover img {
    transform: translateY(0);
}

.how-to-card:nth-child(3) img {
    display: block;
    border-radius: 40px;
    transition: 0.8s;
    transform: translateX(100%);
    position: absolute;
    right: 0;
    bottom: 0;
    aspect-ratio: 1;
    height: 390px;
}

.how-to-card:nth-child(3):hover img {
    transform: translateX(0);
}

.how-to-card:nth-child(4) img {
    position: absolute;
    bottom: 0;
    max-height: 100%;
    height: auto;
    width: auto;
    transition: 0.8s;
}

.how-to-card:nth-child(4) .how-to-card-img-left {
    right: 22%;
    z-index: 15;
    transform: translate(-100%, 100%);
    transition-delay: 0s;
}

.how-to-card:nth-child(4):hover .how-to-card-img-left {
    transform: translate(0);
    transition-delay: 0.3s;
}

.how-to-card:nth-child(4) .how-to-card-img-right {
    right: 0%;
    z-index: 10;
    transform: translate(100%, 100%);
    transition-delay: 0.3s;
}

.how-to-card:nth-child(4):hover .how-to-card-img-right {
    transform: translate(0);
    transition-delay: 0s;
}

#how-to .mobile {
    display: none;
}

@media screen and (max-width: 1200px) {
    #how-to .desktop {
        display: none;
    }

    #how-to .mobile {
        display: flex;
    }

    #how-to h2 {
        font-size: 24px;
    }

    .how-to-cards {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .how-to-card h3 {
        font-size: 20px;
        margin-bottom: 14px;
    }

    .how-to-card-text {
        font-size: 14px;
    }

    .how-to-card {
        padding: 24px;
    }

    .how-to-card:nth-child(1) {
        height: 400px;
    }

    .how-to-card:nth-child(1) img {
        width: 230px;
        height: 130px;
    }

    .how-to-card:nth-child(1) .how-to-card-img-left {
        top: 200px;
        left: -40px;
        transform: rotate(10deg);
    }

    .how-to-card:nth-child(1) .how-to-card-img-right {
        top: 230px;
        right: -40px;
        transform: rotate(-15deg);
        z-index: 10;
    }

    .how-to-card:nth-child(2) {
        height: 290px;
    }

    .how-to-card:nth-child(2) img {
        width: 248px;
        height: 125px;
        margin: 25px auto 0;
        transform: none;
    }

    .how-to-card:nth-child(3) {
        height: 340px;
    }

    .how-to-card:nth-child(3) img {
        width: 253px;
        height: 253px;
        bottom: -34px;
        right: -52px;
        transform: none;
    }

    .how-to-card:nth-child(4) {
        height: 330px;
    }

    .how-to-card:nth-child(4) img {
        width: 265px;
        height: auto;
    }

    .how-to-card:nth-child(4) .how-to-card-img-left {
        right: 50%;
        z-index: 15;
        transform: translate(30%);
    }

    .how-to-card:nth-child(4) .how-to-card-img-right {
        right: 50%;
        z-index: 10;
        transform: translate(75%);
    }
}
</style>
