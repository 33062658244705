<template>
    <a :href="link" class="catalog-card">
        <div class="card-img">
            <img
                v-if="age >= 21"
                src="@/assets/img/old1.png"
                class="card-img-old"
                alt=""
            />
            <img
                v-else-if="gender == 'man'"
                src="@/assets/img/man1.png"
                alt=""
            />
            <img
                v-else-if="gender == 'woman'"
                src="@/assets/img/woman1.png"
                alt=""
            />
            <div class="circle1"></div>
            <div class="circle2"></div>
        </div>
        <div class="card-content">
            <div class="card-content-text">
                <h2>{{ name.trim() + ", " + ageText }}</h2>
                <div class="city-children">
                    <img :src="district_img" alt="" />
                    {{ district }}
                </div>
                <p v-html="text"></p>
            </div>
            <div
                v-if="in_catalog == true && is_ordered == false"
                @click.stop.prevent="booked()"
                class="book-btn"
            >
                забронировать подарок
            </div>
            <div
                v-if="in_catalog == true && is_ordered == true"
                class="book-text"
            >
                <img v-if="ordered_img" :src="ordered_img" alt="" />
                <br />
                <span v-html="ordered_text"></span>
            </div>
        </div>
    </a>
</template>

<script>
import { num_word } from "@/assets/js/helper.js";
import lk_reset from "@/assets/js/api/reset_lk.js";

export default {
    name: "Product",
    props: {
        name: "",
        age: null,
        plus: false,
        age_month: null,
        gender: "man",
        is_ordered: false,
        text: "",
        link: "",
        ordered_text: "",
        ordered_img: "",
        in_catalog: true,
        gift_id: null,
        district: "",
        district_img: "",
        company_inn: "",
        is_company: false,
    },
    methods: {
        booked() {
            this.$store.commit("setGiftId", this.gift_id);
            this.$store.commit("setIsGift", true);
            if (localStorage.getItem("token")) {
                if (
                    this.is_company &&
                    this.$store.state.setData?.company_inn == null
                ) {
                    this.$store.commit("setTypePopup", "company");
                    this.$store.commit("openPopup", true);
                } else {
                    this.$store.commit("setTypePopup", "anonymity");
                    this.$store.commit("openPopup", true);
                }
            } else {
                this.$store.commit("setTypePopup", "avtoriz");
                this.$store.commit("openPopup", true);
            }
        },
    },
    computed: {
        ageText() {
            let text = "";

            if (this.age) {
                text +=
                    num_word(
                        this.age,
                        ["год", "года", "лет"],
                        true,
                        this.plus
                    ) + " ";
            }

            if (this.age_month) {
                text += num_word(
                    this.age_month,
                    ["месяц", "месяца", "месяцев"],
                    true
                );
            }

            return text;
        },
    },
    async mounted() {
        const result = await lk_reset.get();

        if (result?.fields?.is_company == true) {
            this.is_company = result.fields.is_company;
            this.company_inn = result.fields.company_inn;

            if (!this.company_inn) {
                this.$store.commit("setData", {
                    company_inn: null,
                });
            } else {
                this.$store.commit("setData", {
                    company_inn: this.company_inn,
                });
            }
        }
    },
};
</script>
<style>
.catalog-card {
    width: 100%;
    flex-shrink: 0;
    border-radius: 20px;
    min-height: 301px;
    background: #fff;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    gap: 20px;
    transition: 0.8s;
}

.catalog-card:hover {
    box-shadow: 0px 0px 15px 3px rgba(20, 92, 142, 0.3);
}

.card-img {
    position: relative;
    overflow: hidden;
    height: 100%;
    min-width: 186px;
}

.card-img img {
    position: relative;
    z-index: 10;
    position: absolute;
    left: 60px;
    top: 90px;
    width: 125px;
    z-index: 10;
}

.card-img-old {
    left: 55px !important;
    top: 60px !important;
}

.circle1 {
    width: 203.446px;
    height: 203.446px;
    flex-shrink: 0;
    border: 1px solid #4a4a4a;
    border-radius: 50%;
    position: absolute;
    top: -33px;
    left: -89px;
}

.circle2 {
    width: 304px;
    height: 304px;
    flex-shrink: 0;
    border: 1px solid #4a4a4a;
    border-radius: 50%;
    position: absolute;
    top: -79px;
    left: -129px;
}

.book-btn {
    text-align: center;
    white-space: nowrap;
    color: var(--blue);
    font-size: 12px;
    padding: 13px;
    font-style: normal;
    font-weight: 550;
    line-height: 153%;
    text-transform: uppercase;
    border-radius: 13px;
    border: 1px solid var(--blue);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    cursor: pointer;
    margin-bottom: 40px;
    max-width: max-content;
    transition: 0.8s;
}

.city-children {
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.city-children img {
    height: 40px;
}

.city-children {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.book-btn:hover {
    background-color: var(--blue);
    color: #fff;
}

.card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-content h2 {
    color: #264e6b;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    width: auto;
}

.card-content h2::first-letter,
.card-content p::first-letter {
    text-transform: uppercase;
}

.card-content p {
    color: #4a4a4a;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 146.9%;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-right: 39px;
}

.book-text {
    color: #8e141b;
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 153%;
    text-transform: uppercase;
    padding-bottom: 40px;
    padding-right: 20px;
}

.book-text img {
    width: 100%;
    max-width: 100px;
    height: auto;
}

.book-text span {
    color: #8e141b;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: 153%;
    text-transform: uppercase;
}

@media screen and (max-width: 1440px) {
    .catalog-card {
        gap: 20px;
    }

    .card-content h2 {
        font-size: 26px;
    }

    .card-content p {
        font-size: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .circle1 {
        width: 135px;
        height: 135px;
        flex-shrink: 0;
        border: 1px solid #4a4a4a;
        border-radius: 50%;
        position: absolute;
        top: -46px;
        left: -40px;
    }

    .circle2 {
        width: 202px;
        height: 202px;
        flex-shrink: 0;
        border: 1px solid #4a4a4a;
        border-radius: 50%;
        position: absolute;
        top: -76px;
        left: -67px;
    }

    .card-img {
        height: 126px;
    }

    .catalog-card {
        flex-direction: column;
    }

    .card-img img {
        top: 36px;
        left: 38px;
        transform: scale(0.38);
    }

    .card-content h2 {
        color: #264e6b;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .card-content {
        padding: 0px 10px;
        padding-bottom: 50px;
    }

    .book-btn {
        margin-bottom: 0px;
        width: 100%;
        /* font-size: 16px; */
        padding: 17px;
    }

    .card-content p {
        margin: 0px;
        font-size: 18px;
        margin-bottom: 30px;
    }

    .card-content h2 {
        margin: 0px;
        font-size: 26px;
        margin-bottom: 15px;
        margin-top: 24px;
    }
}
</style>
