import Api from "@/assets/js/api/index.js";

class inn extends Api {
    async checkINN(data) {
        try {
            const result = await this.send(
                "methods/checkCompany",
                data,
                "POST"
            );

            return result?.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default new inn();
