<template>
    <div>
        <div class="tabs">
            <p
                v-for="(tab, index) in tabs"
                :key="index"
                class="tablinks"
                @click="changeTabs(index)"
                :class="selectedTab === index ? 'active' : ''"
            >
                {{ tab.title }}
            </p>
        </div>
        <div>
            <Transition name="tab-transition" mode="out-in">
                <div :key="selectedTab">
                    <slot :name="tabs[selectedTab].tab_name"></slot>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
    tabs: Array,
});

const selectedTab = ref(0);

const emit = defineEmits(["current_tab"]);

function changeTabs(index) {
    selectedTab.value = index;
    emit("current_tab", props?.tabs[selectedTab.value]?.tab_name);
}
</script>

<style>
.tab-transition-enter-active,
.tab-transition-leave-active {
    transition: 0.5s;
}

.tab-transition-enter-from,
.tab-transition-leave-to {
    opacity: 0;
    translate: 300px 0;
}

.tabs {
    overflow: hidden;
    display: flex;
    align-items: stretch;
    gap: 14px;
    margin-bottom: 50px;
}

.tabs p {
    font-size: 22px;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    color: #4a4a4a;
    cursor: pointer;
    padding: 20px;
    transition: 0.5s;
    width: 274px;
    margin: 0;
    border: 1px solid #4a4a4a;
    border-radius: 20px;
}

.tabs p:not(.active):hover {
    color: #145c8e;
    border: 1px solid #145c8e;
}

.tabs p.active {
    background-color: #145c8e;
    color: #ffffff;
    border: 1px solid #145c8e;
}

.tab-content {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 24px 0;
    align-items: flex-start;
}

.tab-content-title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--text);
}

.tab-content-subtitle {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    max-width: 480px;
}

.tab-content-subtitle span {
    display: block;
    margin-top: 4px;
    background-color: var(--free_version);
    border-radius: 22px;
    border: 1px solid var(--free_version);
    padding: 4px 16px;
}

.tab-content-btns {
    display: flex;
    gap: 16px;
}

@media screen and (max-width: 1200px) {
    .tabs {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;
    }

    .tabs p {
        font-size: 18px;
        padding: 10px;
    }
}
</style>
