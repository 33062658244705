<template>
    <Page ref="page" :curators="true">
        <Main ref="main" />
        <About :data="page_data?.data?.about" ref="about" />
        <Wishes :data="page_data?.data?.wishes" ref="wishes" />
        <HowTo :data="page_data?.data?.how_to" ref="how_to" />
        <SVGMap />
        <RForm />
        <!-- <Curators :data="page_data?.data?.curators" :regulation="page_data?.data?.regulation"/> -->
    </Page>
</template>

<script>
import Page from "@/components/base/Page.vue";
import Main from "@/components/home/Main.vue";
import About from "@/components/home/About.vue";
import Wishes from "@/components/home/Wishes.vue";
import HowTo from "@/components/home/HowTo.vue";
import SVGMap from "@/components/Map.vue";
import RForm from "@/components/Form.vue";
import Curators from "@/components/home/Curators.vue";

import homeApi from "@/assets/js/api/home.js";
import lk_reset from "@/assets/js/api/reset_lk.js";

import { mapMutations } from "vuex";

export default {
    name: "HomeView",
    components: {
        Page,
        Main,
        About,
        Wishes,
        HowTo,
        SVGMap,
        RForm,
        Curators,
    },
    data() {
        return {
            page_data: null,
            is_company: false,
        };
    },
    methods: {
        ...mapMutations({
            setLoading: "page/setLoading",
        }),
    },
    async mounted() {
        const popupShown = sessionStorage.getItem("popupShown");

        if (!popupShown && localStorage.getItem("token")) {
            const result = await lk_reset.get();

            if (result?.fields?.is_company) {
                this.company_inn = result.fields.company_inn;

                if (!this.company_inn) {
                    this.$store.commit("setData", {
                        company_inn: null,
                    });

                    sessionStorage.setItem("popupShown", "true");
                    sessionStorage.setItem("typePopup", "main-company");
                    sessionStorage.setItem("openPopup", "true");

                    this.$store.commit("setTypePopup", "main-company");
                    this.$store.commit("openPopup", true);
                } else {
                    this.$store.commit("setData", {
                        company_inn: this.company_inn,
                    });
                }
            }
        }

        this.page_data = await homeApi.get();

        const interval = setInterval(() => {
            if (this.is_ready) {
                this.$refs.page.init();
                this.setLoading(false);

                clearInterval(interval);
            }
        }, 50);
    },
    computed: {
        is_ready() {
            return (
                this.$refs.main &&
                this.$refs.about &&
                this.$refs.wishes &&
                this.$refs.how_to
            );
        },
    },
};
</script>
