import axios from "axios";
import router from "@/router";
axios.defaults.withCredentials = true;

class Api {
    constructor(params) {
        this.default_params = {
            url: "https://теплыйдень.рф/v1/",
        };

        this.params = Object.assign(this.default_params, params);
    }

    async send(path, data = null, method = "get") {
        try {
            const axios_params = {
                method,
                url: this.params.url + path,
                data,
            };

            const token = localStorage.getItem("token");

            if (token) {
                axios_params.headers = {
                    Authorization: "Bearer " + JSON.parse(token),
                };
                //  console.log(axios_params);
            }
            // ВЕРНУТЬ! (ломает локалку)

            let result = await axios(axios_params);

            if (result.status != 200) {
                console.log(result);
                return;
            }

            return result;
        } catch (error) {
            if (error.response?.status) {
                switch (error.response.status) {
                    case 403:
                        localStorage.removeItem("token");
                        sessionStorage.removeItem("user");
                        router.push("/");
                        break;

                    default:
                        break;
                }
            }
        }
    }

    async getSiteInfo() {
        const site_info = JSON.parse(sessionStorage.getItem("site_info"));

        if (site_info) {
            return site_info;
        } else {
            const info_result = await this.send("methods/info");

            if (info_result?.data) {
                sessionStorage.setItem(
                    "site_info",
                    JSON.stringify(info_result?.data)
                );
                return info_result?.data;
            }
        }
    }

    async getPrivacyPolicy() {
        const page_data = await this.send("methods/page/privacy-policy");

        return page_data?.data;
    }
}

export default Api;
