<template>
    <section id="about">
        <div class="container about-container">
            <div class="about-text" v-html="data?.text"></div>
            <div class="about-target">
                <div v-html="data?.target"></div>
                <a class="btn" href="/catalog">Каталог</a>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "About",
    props: {
        data: null,
    },
};
</script>
<style>
.about-container {
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 40px;
}

.about-text {
    font-size: 62px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
}

.about-text strong {
    font-style: normal;
    color: var(--red);
}

.about-target {
    font-size: 26px;
    font-style: normal;
    line-height: 140%;
    margin: 20px 0 0 auto;
    font-weight: 600;
}

.about-target div p:first-child strong {
    color: #ff0000;
}

.about-target div p:first-child {
    color: rgba(255, 0, 0, 0.5);
}

.about-target div p:last-child strong {
    color: #000000;
}

.about-target div p:last-child {
    color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1200px) {
    .about-target div p:first-child {
        margin-top: 0;
    }

    .about-container {
        grid-template-columns: 1fr;
        gap: 0px;
    }

    .about-text {
        font-size: 24px;
    }

    .about-target {
        font-size: 16px;
    }
}
</style>
