export function randomInteger(min, max) {
    const rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
}

export function num_word(value, words, show = false, plus = false) {
    let num = value % 100;
    if (num > 19) {
        num = num % 10;
    }

    let out = plus ? value + "+ " : show ? value + " " : "";

    switch (num) {
        case 1:
            out += words[0];
            break;
        case 2:
        case 3:
        case 4:
            out += words[1];
            break;
        default:
            out += words[2];
            break;
    }

    return out;
}

export function replaceNumberForPaste(value) {
    const r = value.replace(/\D/g, "");
    let val = r;
    if (val.charAt(0) === "7") {
        val = "8" + val.slice(1);
    }

    return replaceNumberForInput(val);
}

function replaceNumberForInput(value) {
    let val = "";
    const x = value
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (x[1] === "") {
        val = "";
    } else if (!x[2] && x[1] !== "") {
        if (x[1] === "8" || x[1] === "7") {
            val = "+7";
        } else {
            val = "+7" + x[1];
        }
    } else {
        // val = !x[3] ? '+7' + x[2] : '+7 (' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '') + (x[5] ? '-' + x[5] : '');
        val = !x[3]
            ? "+7" + x[2]
            : "+7 (" +
              x[2] +
              ") " +
              x[3] +
              (x[4] ? " " + x[4] : "") +
              (x[5] ? " " + x[5] : "");
    }

    return val;
}

export function validateEmail(email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}
