<template>
    <!-- <h2 class="not-start">Акция пока не началась</h2> -->
    <Preloader />
    <router-view />
</template>

<script>
import Preloader from "@/components/base/Preloader.vue";

export default {
    name: "App",
    components: {
        Preloader,
    },
    async mounted() {},
};
</script>

<style>
.not-start {
    height: 100vh;
    width: 100vw;
    display: grid;
    place-content: center;
    padding: 20px;
}

@font-face {
    font-family: "Masiva";
    src: url("./assets/fonts/Masiva/Masiva-Regular.eot");
    src: local("Masiva"),
        url("./assets/fonts/Masiva/Masiva-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/Masiva/Masiva-Regular.woff2 ") format("woff2"),
        url("./assets/fonts/Masiva/Masiva-Regular.woff") format("woff"),
        url("./assets/fonts/Masiva/Masiva-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

:root {
    --black: #0b0715;
    --red: #c10e3f;
    --blue: #69a0c7;
    --light-gray: #f3f3f3;
}

* {
    box-sizing: border-box;
    font-family: "Masiva";
}

body {
    font-size: 2.7vh;
    margin: 0;
    overflow-x: hidden;
    color: var(--black);
}

.scroll-content {
    will-change: transform;
}

section {
    padding-bottom: 100px;
}

.container {
    max-width: 1440px;
    padding: 0 20px;
    margin: 0 auto;
}

.container.align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scene {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

h1 {
    color: #07395c;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    gap: 1vh;
    z-index: 10;
}

h1 span {
    font-size: 4vh;
    position: relative;
    display: flex;
    align-items: center;
    text-shadow: 0 0 2vh #fff;
    width: max-content;
}

h1 span::after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 1px;
    background-color: #b4b4b4;
    right: 1vh;
    z-index: -1;
}

h2 {
    color: #07395c;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    width: max-content;
    gap: 1vh;
    z-index: 10;
}

h2 span {
    font-size: 4vh;
    position: relative;
    display: flex;
    align-items: center;
    text-shadow: 0 0 2vh #fff;
    width: max-content;
}

h2 span::after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 1px;
    background-color: #b4b4b4;
    right: 1vh;
    z-index: -1;
}

a.btn {
    padding: 32px 140px 32px 140px;
    border-radius: 24px;
    border: 1px solid var(--red);
    background-color: var(--red);
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%;
    transition: 0.5s;
}

a.btn:hover {
    color: var(--red);
    background-color: #fff;
}

a.btn.center {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1200px) {
    a.btn {
        padding: 20px;
        width: 100%;
        font-size: 16px;
    }
}
</style>
