<template>
    <div
        @click="mobileMenu"
        class="bg-men"
        :class="menu_hidden == true ? 'active' : ''"
    ></div>
    <header :class="[is_open ? 'opened' : '', menu_hidden == true ? 'bg' : '']">
        <div class="container">
            <a href="/" class="logo-conteiner">
                <!-- <img :src="require('@/assets/img/logo-1.svg')" alt="">
            |  -->
                <img :src="$store.state?.site_info?.logo ? $store.state?.site_info?.logo[0]?.logo_pc : require('@/assets/img/logo.png')" alt="" />
            </a>
            <div
                @click="mobileMenu"
                class="mobile-menu-btn"
                :class="menu_hidden == true ? 'active' : ''"
            >
                <svg
                    class="open"
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="19"
                    viewBox="0 0 33 19"
                    fill="none"
                >
                    <path
                        d="M4.125 2.57227H28.875"
                        stroke="#343434"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M4.125 9.5H28.875"
                        stroke="#343434"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M4.125 16.4258H28.875"
                        stroke="#343434"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </svg>
                <svg
                    class="close"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M14.0625 3.9375L3.9375 14.0625"
                        stroke="#C5C5C5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M14.0625 14.0625L3.9375 3.9375"
                        stroke="#C5C5C5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            <div class="menu" :class="menu_hidden == true ? 'active' : ''">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="close-menu"
                    @click="mobileMenu"
                >
                    <path
                        d="M15 1L1 15"
                        stroke="#C10E3F"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M15 15L1 1"
                        stroke="#C10E3F"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <a href="/">Главная</a>
                <a v-if="$route.path == '/'" @click="moveTo('#about')">
                    Об акции
                </a>
                <a v-else href="/?section=about">Об акции</a>
                <a href="/catalog">Каталог</a>
                <a @click="moveTo('#map')">Места приёмов</a>
                <!-- <a v-if="$route.path == '/'" @click="moveTo('#curators')">
                    Партнеры
                </a>
                <a v-else href="/?section=curators">Партнеры</a> -->
                <a href="/contacts">Контакты</a>

                <a v-if="user_token" href="/lk" class="menu-btn">
                    Личный кабинет
                </a>
                <a v-else @click="openAvtoriz" class="menu-btn">
                    Личный кабинет
                </a>

                <!-- <div class="mobile-contacts">
                    <hr />
                    <a :href="'tel:' + $store.state.site_info?.phone">{{
                        $store.state.site_info?.phone
                    }}</a>
                    <a :href="'mailto:' + $store.state.site_info?.email">{{
                        $store.state.site_info?.email
                    }}</a>
                    <div class="socials-vk">
                        <span> Мы в соц. сетях: </span>
                        <a
                            :href="item.link"
                            target="_blank"
                            v-for="item in $store.state.site_info?.media"
                        >
                            <img :src="item.img" alt="" />
                        </a>
                    </div>
                </div> -->
            </div>
        </div>
    </header>
</template>
<script>
import Api from "@/assets/js/api";
import { mapMutations } from "vuex";

export default {
    props: {
        moveTo: null,
        is_hidden: false,
    },
    data() {
        return {
            menu_hidden: false,
            is_open: false,
        };
    },
    methods: {
        ...mapMutations({
            site_info: "site_info",
        }),

        openSlelect() {
            this.$store.commit("setTypePopup", "select-district");
            this.$store.commit("openPopup", true);
        },
        openAvtoriz() {
            this.$store.commit("setTypePopup", "avtoriz");
            this.$store.commit("openPopup", true);
        },
        mobileMenu() {
            this.menu_hidden = !this.menu_hidden;
        },
        mobileHidden() {
            this.menu_hidden = false;
        },
    },
    computed: {
        user_token() {
            if (this.$store.state?.token) return true;
            else return false;
        },
    },
    async mounted() {
        if (this.is_hidden) {
            document.addEventListener("scroll", (e) => {
                this.is_open = window.scrollY > 0;
            });
        } else {
            this.is_open = true;
        }

        const api = new Api();
        this.data = await api.getSiteInfo();
        this.site_info(this.data);
    },
};
</script>
<style>
header {
    padding: 10px 40px;
    background-color: #fff;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.5s;
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: all;
}

header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

header.opened {
    transform: translateY(0);
    opacity: 1;
}

/* header::after {
    transition: 0.5s;
    content: "";
} */

/* header.bg::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
} */
header.bg:not(.menu) {
    background-color: rgba(128, 128, 128, 0.2);
}

body:has(.popup-filter.open) header {
    opacity: 0;
    pointer-events: none;
}

header.bg .logo-conteiner,
header.bg .mobile-menu-btn {
    opacity: 0;
}

header.bg {
    pointer-events: none;
}

.logo-conteiner {
    display: flex;
    align-items: center;
    color: #145c8e;
    text-decoration: none;
    gap: 10px;
    transition: 0.5s;
}

.logo-conteiner img {
    height: 45px;
}

.menu {
    display: flex;
    align-items: center;
    gap: 40px;
}

.menu .close-menu {
    display: none;
}

.menu a {
    text-decoration: none;
    color: #4a4a4a;
    transition: 0.5s;
    font-size: 14px;
    font-weight: 400;
    line-height: 13.93px;
    cursor: pointer;
}

.menu a:hover {
    color: #145c8e;
}

.city {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    color: #4a4a4a;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}

.city-img {
    width: 26px;
    height: 26px;
}

.city-img img {
    height: 100%;
    width: auto;
}

.mobile-menu-btn {
    display: none;
    transition: 0.5s;
}

.bg-men {
    display: none;
}

.mobile-contacts {
    display: none;
}

a.menu-btn {
    padding: 15px 19px;
    border-radius: 120px;
    color: #fff;
    background-color: var(--red);
}

a.menu-btn:hover {
    color: #fff;
}

@media screen and (max-width: 1500px) {
    .menu {
        gap: 30px;
    }
}

@media screen and (max-width: 1440px) {
    .menu {
        gap: 20px;
    }

    .menu a {
        font-size: 16px;
    }

    .logo-conteiner img {
        height: 35px;
    }
}

@media screen and (max-width: 1200px) {
    header .container {
        padding: 0;
    }

    .mobile-menu-btn {
        display: block;
    }

    header {
        padding: 20px;
    }

    .logo-conteiner img {
        height: 22px;
    }

    .city {
        font-size: 12px;
    }

    .city p {
        display: none;
    }

    .menu {
        position: absolute;
        top: 40px;
        left: 50%;
        padding: 60px 25px 32px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        transition: 0.5s;
        translate: -50% 0;
        scale: 0;
        pointer-events: none;
        height: auto;
        max-height: 80lvh;
        width: calc(100% - 40px);
        max-width: 540px;
        overflow: auto;
        z-index: 150;
        border-radius: 18px;
    }

    .menu .close-menu {
        display: block;
        position: absolute;
        right: 18px;
        top: 18px;
    }

    .menu.active {
        pointer-events: all;
        scale: 1;
    }

    .menu a {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        color: #212121;
        width: 100%;
    }

    .menu a:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(33, 33, 33, 0.4);
    }

    .bg-men {
        position: fixed;
        display: block;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 100;
        transition: 0.5s;
        opacity: 0;
        pointer-events: none;
    }

    .bg-men.active {
        opacity: 1;
        pointer-events: all;
    }

    .mobile-contacts {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .socials-vk {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        color: #383232;
    }

    .socials-vk span {
        font-size: 22px;
        color: #383232;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-contacts {
        width: 100%;
    }

    .mobile-menu-btn {
        position: relative;
        width: 33px;
        height: 19px;
    }

    .mobile-menu-btn svg {
        transition: 0.5s;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .mobile-menu-btn .close {
        opacity: 0;
    }

    .mobile-menu-btn.active .open {
        opacity: 0;
    }

    .mobile-menu-btn.active .close {
        opacity: 1;
    }

    a.menu-btn {
        padding: 0;
        border-radius: 0;
        background-color: transparent;
    }
}
</style>
